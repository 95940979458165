import React from "react";

const Faq = () => {
  return (
    <div className="pb-13 pb-md-18 pb-lg-25">
      <div className="container">
        {/* Section Title */}
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-9">
            <div className="text-center mb-13 mb-lg-19">
              <h2 className="font-size-9 mb-0">Perguntas Frequentes</h2>
            </div>
          </div>
        </div>
        {/* End Section Title */}
        {/* Faqs */}
        {/* Faqs */}
        <div className="row justify-content-center">
          {/* Single Faq */}
          <div className="col-lg-6 col-md-10">
            <div
              className="d-flex mb-10 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={300}
            >
              <div className="mr-6">
                <div className="bg-light-orange circle-28 text-white mt-1">
                  <i className="fa fa-question" />
                </div>
              </div>
              <div className="">
                <h4 className="font-size-7 mb-7">
                  Como funcionam os planos mensal e anual?
                </h4>
                <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                  No plano mensal você desembolsa o valor indicado mensalmente. Se você cancelar o plano, não haverá cobrança no próximo mês.
                  No plano anual, o valor a ser pago é o valor indicado para 12 meses. Ou seja, se o valor indicado é R$ 150, você desembolsará
                  o valor de R$ 1.800. Seu plano ficará ativo por um ano. Não há reembolso parcial do valor pago.
                </p>
              </div>
            </div>
          </div>
          {/* End Single Faq */}
          {/* Single Faq */}
          <div className="col-lg-6 col-md-10">
            <div
              className="d-flex mb-10 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <div className="mr-6">
                <div className="bg-light-orange circle-28 text-white mt-1">
                  <i className="fa fa-question" />
                </div>
              </div>
              <div className="">
                <h4 className="font-size-7 mb-7">
                  A franquia de uso acumula para o mês seguinte?
                </h4>
                <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                  Não, a sua franquia de uso não acumula para o mês seguinte.
                  Por exemplo, se seu plano permite efetuar 1.000 operações fiscais por mês, e você efetuou apenas 800 operações nesse período,
                  no próximo mês você terá novamente direito a 1.000 operações mensais.
                </p>
              </div>
            </div>
          </div>
          {/* End Single Faq */}
          {/* Single Faq */}
          <div className="col-lg-6 col-md-10">
            <div
              className="d-flex mb-10 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={300}
            >
              <div className="mr-6">
                <div className="bg-light-orange circle-28 text-white mt-1">
                  <i className="fa fa-question" />
                </div>
              </div>
              <div className="">
                <h4 className="font-size-7 mb-7">
                  O que são operações fiscais?
                </h4>
                <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                  Na maioria dos casos, uma operação fiscal é a emissão de um documento fiscal. Portanto, se o seu plano permite 1000 operações fiscais
                  por mês, você deve conseguir emitir aproximadamente 1000 documentos fiscais. Porém, algumas outras operações na API são também
                  contabilizadas como uma operação fiscal, como por exemplo o cancelamento ou inutilização de uma nota fiscal. 
                  <a href="https://dev.nuvemfiscal.com.br/docs/limites#dfe-eventos">Consulte a nossa documentação para informações detalhadas</a> sobre como são contabilizadas as operações fiscais.
                </p>
              </div>
            </div>
          </div>
          {/* End Single Faq */}
          {/* Single Faq */}
          <div className="col-lg-6 col-md-10">
            <div
              className="d-flex mb-10 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={300}
            >
              <div className="mr-6">
                <div className="bg-light-orange circle-28 text-white mt-1">
                  <i className="fa fa-question" />
                </div>
              </div>
              <div className="">
                <h4 className="font-size-7 mb-7">
                  Quais as formas de pagamento aceitas?
                </h4>
                <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                  Os pagamentos podem ser realizados via boleto bancário ou cartão de crédito. 
                </p>
              </div>
            </div>
          </div>
          {/* End Single Faq */}
          {/* Single Faq */}
          <div className="col-lg-6 col-md-10">
            <div
              className="d-flex mb-10 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <div className="mr-6">
                <div className="bg-light-orange circle-28 text-white mt-1">
                  <i className="fa fa-question" />
                </div>
              </div>
              <div className="">
                <h4 className="font-size-7 mb-7">A assinatura é renovada automaticamente?</h4>
                <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                  Sim, após o uso do período contratado (mensal ou anual), uma nova cobrança é realizada em seu cartão, ou um novo boleto é emitido.
                  Caso não queira mais utilizar os nossos serviços, você precisa cancelar a sua assinatura. A sua assinatura pode ser cancelada a qualquer
                  momento pela sua área de cliente, sem a necessidade de entrar em contato conosco.
                </p>
              </div>
            </div>
          </div>
          {/* End Single Faq */}
        </div>
        {/* Button  */}
        <div
          className="text-center pt-lg-3 pb-lg-3"
          data-aos="fade-up"
          data-aos-duration={500}
        >
          <p className="font-size-6 mb-0">
            Ainda tem dúvidas? Visite nossa&nbsp;
              <a className="text-blue-3 btn-link-with-underline" href="https://suporte.nuvemfiscal.com.br">Central de Suporte</a>.
          </p>
        </div>
        {/* Button End */}
      </div>
    </div>
  );
};

export default Faq;
