import React from "react";
import PricingTable from '../../components/Pricing';

const Pricing = () => {
  return (
    <>
    <div className="pb-5 pb-md-11 pb-lg-19">
      <div className="container">
        <PricingTable fontColor='#1d293f' btnBackground='#0f53fa' btnColor='#fff'/>
      </div>
    </div>
    </>
  );
};

export default Pricing;
